import "./home.css"
import Navbar from "../components/navbar/navbar"
import Contact from "components/contact"
import Product from "./product"
import { Link } from "react-scroll"
import { FaRegHandshake } from "react-icons/fa6"
import { MdOutlinePower, MdOutlineFactory } from "react-icons/md"
import { AiOutlineLineChart } from "react-icons/ai"
import HeroImg from "../assets/hero.jpg"
import { useEffect } from "react"
import { useState } from 'react'

function Home() {
    /* const [distance, setDistance] = useState(0)
    useEffect(() => {
        const objectElement = document.querySelector('#hero');
        const topPositionInPixels = objectElement.getBoundingClientRect().top;
        setDistance(topPositionInPixels*0.0625)
    }, []) */
    return (
        <div id="land">
            <Navbar />
            <div className="md:container px-5 md:mx-auto">
                <div id="hero" className={`lg:h-[calc(100vh-8.75rem)]`}>
                    <div className="xl:bg-[#eae8ea] lg:mt-10 mt-2 2xl:w-4/5 w-full rounded-xl">
                        <div className="p-2 flex xl:gap-[75px] lg:gap-[50px] lg:flex-row flex-col-reverse">
                        <div>
                            <div className="page-title xl:text-5xl w-full lg:text-4xl md:text-4xl text-2xl lg:m-5 mt-2 font-semibold">
                                Çeşitli güç kaynağı ve adaptör ihtiyaçlarınız için doğru adres
                            </div>
                            <p className="lg:m-5 mt-2 lg:text-left text-justify md:text-xl sm:text-lg text-black">Ürünlerimiz sadece bugünün değil, yarının ihtiyaçlarına da cevap verebilmek üzere özenle inşa edilir. Size güvenilirlik ve dayanıklılığın ayrıcalığını sunuyoruz.</p>
                            <div className="lg:m-5 mt-5 bg-black inline-block">
                            <Link to="product" activeClass="active" duration={1000} spy={true} smooth={true}
                                className="xl:px-5 xl:py-3 px-3 py-2 rounded-md text-md bg-black text-white font-semibold cursor-pointer">Ürünlerimize Göz Atın</Link>
                            </div>
                        </div>
                        <img className="xl:h-96 w-full lg:h-72 h-60 object-cover rounded-xl" src={HeroImg} alt="hero-img" />
                        </div>
                    </div>
                    <div className="flex justify-center items-center xl:mt-[125px] mt-10">
                    <div className="w-full grid xl:grid-cols-4 grid-cols-2 gap-[60px] flex-row">
                        <div className="flex flex-col items-center">
                            <MdOutlinePower className="xl:text-[60px] md:text-[50px] text-[40px] text-black" />
                            <div className="lg:text-xl text-md font-semibold text-center">Geniş Ürün Portföyü</div>
                        </div>
                        <div className="flex flex-col items-center">
                            <AiOutlineLineChart className="xl:text-[60px] md:text-[50px] text-[40px] text-black" />
                            <div className="lg:text-xl text-md font-semibold text-center">
                                <p>Yüksek Performans</p>
                                <p>Düşük Risk</p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <MdOutlineFactory className="xl:text-[60px] md:text-[50px] text-[40px] text-black" />
                            <div className="lg:text-xl text-md font-semibold text-center">
                                <p>Özelleştirilmiş</p>
                                <p>Üretim</p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center">
                            <FaRegHandshake className="xl:text-[60px] md:text-[50px] text-[40px] text-black" />
                            <div className="lg:text-xl text-md font-semibold text-center">Müşteri Odaklılık</div>
                        </div>
                    </div>
                    </div>
                </div>
                <Product />
                <Contact />
            </div>
        </div>
    )
}

export default Home
import "./navbar.css"
import { HiOutlineMenuAlt3 } from "react-icons/hi"
import { FaPhone, FaBox } from "react-icons/fa"

import {
    Box,
    Drawer,
    List,
    ListItem,
    Stack
} from '@mui/material';
import { useState } from "react";
/* import { Link } from "react-router-dom"; */
import { Link } from 'react-scroll'


function Navbar() {
    /* const [isSticky, setIsSticky] = useState(false);
  
    const handleScroll = () => {
        const element = document.querySelector('#contact');
        const rect = element.getBoundingClientRect();
        const startingHeight = rect.top + window.scrollY;
        if (window.scrollY > startingHeight - 1) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []); */

    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            text: "Ürünler",
            to: "product",
            icon: <FaBox />
        },
        {
            text: "İletişim",
            to: "contact",
            icon: <FaPhone />
        },
    ]
    /* ${isSticky ? 'sticky top-0 bg-white' : ''} */
    return (
        <nav className={`md:container p-5 md:mx-auto z-10`}>
            <div className="xl:text-6xl lg:text-6xl md:text-4xl text-2xl font-bold">
                <h1>KSN POWER</h1>
            </div>
            <div className="navbar-links-container">
                <Stack direction="row" spacing={2}>
                    {/* <Link activeClass="active" to="land" spy={true} smooth={true} duration={1000} className="ease-in duration-300 text-gray-600 hover:text-black">Anasayfa</Link> */}
                    <Link activeClass="active" to="product" spy={true} smooth={true} duration={1000} className="ease-in duration-300 text-gray-600 hover:text-black">Ürünlerimiz</Link>
                    <Link activeClass="active" to="contact" spy={true} smooth={true} duration={1000} className="ease-in duration-300 text-gray-600 hover:text-black" >İletişim</Link>
                    <HiOutlineMenuAlt3 onClick={() => setOpenMenu(true)} />
                </Stack>
            </div>
            <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
                <Box
                    sx={{ width: 250 }}
                    onClick={() => setOpenMenu(false)}
                    onKeyDown={() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} className="p-5 text-lg" disablePadding>
                                <Link activeClass="active" className="flex gap-5 items-center w-full" to={item.to} spy={true} smooth={true} duration={1000}>
                                    {item.icon}
                                    {item.text}</Link>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </nav>
    )
}

export default Navbar
/* import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes"; */
import "./style.css"
import Home from "pages/home";

/* function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
} */

function App() {
  return (
    <Home />
  );
}

export default App;

export const product_data = [
    {
        title: "PCB Tipi Güç Kaynakları", subcategories: [
            {
                title: "KSN - PCB25 SERİSİ", products: [
                    {
                        title: "KSN - PCB25 - 24V / 1A",
                        desc: "PCB TİPİ GÜÇ KAYNAĞI 25WATT / 24V / 1A"
                    },
                    {
                        title: "KSN - PCB25 - 12V / 2A",
                        desc: "PCB TİPİ GÜÇ KAYNAĞI 25WATT / 12V / 2A"
                    },
                ]
            }
        ]
    },
    {
        title: "Sanayi Tipi Adaptörler", subcategories: [
            {
                title: "KSN 25-60 - S SERİSİ", products: [
                    { title: "KSN60 - S - 12V / 5,0A", desc: "SANAYİ TİPİ ADAPTÖR 60WATT / 12V / 5,0A" },
                    { title: "KSN25 - S - 12V / 2,1A", desc: "SANAYİ TİPİ ADAPTÖR 25WATT / 12V / 2,1A" },
                ]
            },
            {
                title: "KSN 100-120 - S SERİSİ", products: [
                    { title: "KSN120 - S - 12V / 10A", desc: "SANAYİ TİPİ ADAPTÖR 120WATT / 12V / 10A" },
                    { title: "KSN100 - S - 12V / 8,3A", desc: "SANAYİ TİPİ ADAPTÖR 100WATT / 12V / 8,3A" },
                ]
            },
            {
                title: "KSN 150 - S SERİSİ", products: [
                    { title: "KSN150 - S - 12V / 12,5A", desc: "SANAYİ TİPİ ADAPTÖR 150WATT / 12V / 12,5A" },
                    { title: "KSN150 - S - 5V / 3A", desc: "SANAYİ TİPİ ADAPTÖR 150WATT / 5V / 3A" },
                ]
            },
            {
                title: "KSN 200 - S SERİSİ", products: [
                    { title: "KSN200 - S - 48V / 4,2A", desc: "SANAYİ TİPİ ADAPTÖR 200WATT / 48V / 4,2A" },
                    { title: "KSN200 - S - 24V / 8,3A", desc: "SANAYİ TİPİ ADAPTÖR 200WATT / 24V / 8,3A" },
                    { title: "KSN200 - S - 12V / 16,7A", desc: "SANAYİ TİPİ ADAPTÖR 200WATT / 12V / 16,7A" },
                    { title: "KSN200 - S - 5V / 40A", desc: "SANAYİ TİPİ ADAPTÖR 200WATT / 5V / 40A" },
                ]
            },
            {
                title: "KSN 350-360-500 - S SERİSİ", products: [
                    { title: "KSN500 - S - 12V / 40A", desc: "SANAYİ TİPİ ADAPTÖR 500WATT / 12V / 40A" },
                    { title: "KSN360 - S - 12V / 30A", desc: "SANAYİ TİPİ ADAPTÖR 360WATT / 12V / 30A" },
                    { title: "KSN350 - S - 5V / 70A", desc: "SANAYİ TİPİ ADAPTÖR 350WATT / 5V / 70A" },
                ]
            },
        ]
    },
    {
        title: "Masa Tipi Adaptörler", subcategories: [
            {
                title: "KSN 12 - M SERİSİ", products: [
                    { title: "KSN12 - M - 48V / 0,35A", desc: "MASA TİPİ ADAPTÖR 12WATT / 48V / 0,35A" },
                    { title: "KSN12 - M - 24V / 0,5A", desc: "MASA TİPİ ADAPTÖR 12WATT / 24V / 0,5A" },
                    { title: "KSN12 - M - 12V / 1,0A", desc: "MASA TİPİ ADAPTÖR 12WATT / 12V / 1,0A" },
                    { title: "KSN12 - M - 9,0V / 1,5A", desc: "MASA TİPİ ADAPTÖR 12WATT / 9,0V / 1,5A" },
                    { title: "KSN12 - M - 8,4V / 1,6A", desc: "MASA TİPİ ADAPTÖR 12WATT / 8,4V / 1,6A" },
                    { title: "KSN12 - M - 7,8V / 1,2A", desc: "MASA TİPİ ADAPTÖR 12WATT / 7,8V / 1,2A" },
                    { title: "KSN12 - M - 6,0V / 2,0A", desc: "MASA TİPİ ADAPTÖR 12WATT / 6,0V / 2,0A" },
                    { title: "KSN12 - M - 5,0V / 2,0A", desc: "MASA TİPİ ADAPTÖR 12WATT / 5,0V / 2,0A" },
                    { title: "KSN12 - M - 3,0V / 2,0A", desc: "MASA TİPİ ADAPTÖR 12WATT / 3,0V / 2,0A" },
                ]
            },
            {
                title: "KSN 18 - M SERİSİ", products: [
                    { title: "KSN18 - M - 24V / 0,8A", desc: "MASA TİPİ ADAPTÖR 18WATT / 24V / 0,8A" },
                    { title: "KSN18 - M - 12V / 1,5A", desc: "MASA TİPİ ADAPTÖR 18WATT / 12V / 1,5A" },
                    { title: "KSN18 - M - 9,0V / 2,0A", desc: "MASA TİPİ ADAPTÖR 18WATT / 9,0V / 2,0A" },
                    { title: "KSN18 - M - 6,0V / 3,0A", desc: "MASA TİPİ ADAPTÖR 18WATT / 6,0V / 3,0A" },
                    { title: "KSN18 - M - 5,0V / 3,0A", desc: "MASA TİPİ ADAPTÖR 18WATT / 5,0V / 3,0A" },
                ]
            },
            {
                title: "KSN 25 - M SERİSİ", products: [
                    { title: "KSN25 - M - 24V / 1A", desc: "MASA TİPİ ADAPTÖR 25WATT / 24V / 1A" },
                    { title: "KSN25 - M - 12V / 2A", desc: "MASA TİPİ ADAPTÖR 25WATT / 12V / 2A" },
                ]
            },
            {
                title: "KSN 40 - M SERİSİ", products: [
                    { title: "KSN40 - M - 24V / 1,5A", desc: "MASA TİPİ ADAPTÖR 40WATT / 24V / 1,5A" },
                    { title: "KSN40 - M - 12V / 3A", desc: "MASA TİPİ ADAPTÖR 40WATT / 12V / 3A" },
                ]
            },
            {
                title: "KSN 60 - M SERİSİ", products: [
                    { title: "KSN60 - M - 24V / 2,5A", desc: "MASA TİPİ ADAPTÖR 60WATT / 24V / 2,5A" },
                    { title: "KSN60 - M - 12V / 5A", desc: "MASA TİPİ ADAPTÖR 60WATT / 12V / 5A" },
                ]
            }
        ]
    },
    {
        title: "Priz Tipi Adaptörler", subcategories: [
            {
                title: "KSN 12 - P SERİSİ", products: [
                    { title: "KSN12 - P - 48V / 0,35A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 48V / 0,35A" },
                    { title: "KSN12 - P - 24V / 0,5A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 24V / 0,5A" },
                    { title: "KSN12 - P - 12V / 1,0A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 12V / 1,0A" },
                    { title: "KSN12 - P - 9,0V / 1,5A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 9,0V / 1,5A" },
                    { title: "KSN12 - P - 8,4V / 1,6A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 8,4V / 1,6A" },
                    { title: "KSN12 - P - 7,8V / 1,2A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 7,8V / 1,2A" },
                    { title: "KSN12 - P - 6,0V / 2,0A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 6,0V / 2,0A" },
                    { title: "KSN12 - P - 5,0V / 2,0A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 5,0V / 2,0A" },
                    { title: "KSN12 - P - 3,0V / 2,0A", desc: "PRİZ TİPİ ADAPTÖR 12WATT / 3,0V / 2,0A" },
                ]
            },
            {
                title: "KSN 18 - P SERİSİ", products: [
                    { title: "KSN18 - P - 24V / 0,8A", desc: "PRİZ TİPİ ADAPTÖR 18WATT / 24V / 0,8A" },
                    { title: "KSN18 - P - 12V / 1,5A", desc: "PRİZ TİPİ ADAPTÖR 18WATT / 12V / 1,5A" },
                    { title: "KSN18 - P - 9,0V / 2,0A", desc: "PRİZ TİPİ ADAPTÖR 18WATT / 9,0V / 2,0A" },
                    { title: "KSN18 - P - 6,0V / 3,0A", desc: "PRİZ TİPİ ADAPTÖR 18WATT / 6,0V / 3,0A" },
                    { title: "KSN18 - P - 5,0V / 3,0A", desc: "PRİZ TİPİ ADAPTÖR 18WATT / 5,0V / 3,0A" },
                ]
            },
            {
                title: "KSN 25 - P SERİSİ", products: [
                    { title: "KSN25 - P - 24V / 1A", desc: "PRİZ TİPİ ADAPTÖR 25WATT / 24V / 1A" },
                    { title: "KSN25 - P - 12V / 2A", desc: "PRİZ TİPİ ADAPTÖR 25WATT / 12V / 2A" },
                ]
            },
            {
                title: "KSN 40 - P SERİSİ", products: [
                    { title: "KSN40 - P - 24V / 1,5A", desc: "PRİZ TİPİ ADAPTÖR 40WATT / 24V / 1,5A" },
                    { title: "KSN40 - P - 12V / 3A", desc: "PRİZ TİPİ ADAPTÖR 40WATT / 12V / 3A" },
                ]
            },
        ]
    },
]
import { FaLocationPin, FaPhone, FaEnvelope } from "react-icons/fa6"

export default function Contact() {
    return (
        <div id="contact" className="flex flex-col py-5 lg:h-screen">
            <h1 className="xl:text-4xl lg:text-3xl md:text-2xl lg:text-center text-2xl font-bold">
                Bize Ulaşın
            </h1>
            {/* <div className="border-500 border-black  border-b-8 w-20 mt-5"></div> */}
            <div className="flex xl:flex-row flex-col gap-10 lg:mt-10 mt-5">
                <div className="grid xl:grid-flow-row xl:gap-10 lg:gap-7 md:gap-5 gap-2 w-full">
                    <div className="xl:text-2xl lg:text-xl md:text-xl text-lg font-semibold flex gap-5 items-center py-5">
                        <FaPhone className="xl:text-3xl text-xl" />
                        <span className="basis-full">0212 000 000</span>
                    </div>
                    <div className="xl:text-2xl lg:text-xl md:text-xl text-lg font-semibold flex gap-5 items-center py-5">
                        <FaEnvelope className="xl:text-3xl text-xl" />
                        <span className="basis-full">info@ksnpower.com</span>
                    </div>
                    <div className="xl:text-2xl lg:text-xl md:text-xl text-lg font-semibold flex gap-5 items-center py-5">
                        <FaLocationPin className="xl:text-3xl text-xl" />
                        <span className="basis-full">Azapkapı, Hoca Hanım Sokak. No: 21. Kat: 4. Beyoğlu / İstanbul</span>
                    </div>
                </div>
                <div className="w-full h-96">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1505.0089803359228!2d28.97096!3d41.024863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9e60f956601%3A0x2451a7dc06e2b7bd!2zQXphcGthcMSxLCBIb2NhIEhhbsSxbSBTay4gTm86MjEsIDM0NDIxIEJleW_En2x1L8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1692892204700!5m2!1str!2str" 
                    className="w-full h-full" loading="lazy"></iframe>
                </div>
            </div>
        </div>
    )
}
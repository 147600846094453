import { useState } from 'react'
import {product_data} from './product_data'

export default function Product() {
    const [activeIndex, setActiveIndex] = useState(0)
    const [subActiveIndex, setSubActiveIndex] = useState(0)
    return (
        <div id="product" className="xl:h-screen flex flex-col">
            <span className='lg:text-5xl text-3xl font-bold mt-10'>Ürünlerimiz</span>
            <div className="flex flex-row gap-5 my-5 overflow-x-auto mt-5">
                {product_data.map((item, index) => (
                    <button disabled={activeIndex === index && true} key={item.title} className={`${activeIndex === index ? "bg-black text-white" : "bg-[#eae8ea] text-black"} flex min-w-fit items-center rounded-xl p-1 px-5 cursor-pointer ease-in duration-150 text-lg font-semibold`} 
                    onClick={() => {setSubActiveIndex(0); setActiveIndex(index)}}>{item.title}</button>
                ))}
            </div>
            <div className="flex flex-row gap-5 lg:my-5 mt-2 overflow-x-auto">
            {product_data[activeIndex].subcategories && product_data[activeIndex].subcategories.map((item, index) => (
                    <button disabled={subActiveIndex === index && true} key={item.title} className={`${subActiveIndex === index ? "bg-black text-white" : "bg-[#eae8ea] text-black"} flex min-w-fit items-center rounded-xl p-1 px-5 cursor-pointer ease-in duration-150 text-lg font-semibold`} 
                    onClick={() => setSubActiveIndex(index)}>{item.title}</button>
                ))}
            </div>
            <div className='flex md:flex-row flex-col mt-5'>
            <img className='lg:h-72 h-64 object-contain'
             src='https://platincdn.com/3476/pictures/thumb/500X-CXXCQECHGH7302023164624_R3X86A.webp' alt={product_data[activeIndex].subcategories && product_data[activeIndex].subcategories[subActiveIndex].title} />
            <div className='grid h-fit lg:grid-cols-4 grid-cols-2 gap-5 lg:mt-0 mt-5'>
            {product_data[activeIndex].subcategories && product_data[activeIndex].subcategories[subActiveIndex].products.map((item, index) => (
                <div key={item.title} className='font-semibold p-2 rounded-lg'>
                    <p className='mb-2'>{item.title}</p>
                    <p>{item.desc}</p>
                </div>
            ))}
            </div>
            </div>
        </div>
    )
}